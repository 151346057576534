<script setup lang="ts">
import { TagType } from '~/src/enums/Tag'

interface Props {
  type?: TagType
  dotClass?: string
}

const props = withDefaults(defineProps<Props>(), {
  type: TagType.Primary,
  dotClass: 'bg-black-50',
})
</script>

<template>
  <span
    class="tag"
    :class="[`tag--${props.type}`]"
  >
    <span
      v-if="type === TagType.Primary"
      class="dot"
      :class="[props.dotClass]"
    />
    <slot />
  </span>
</template>

<style scoped lang="postcss">
/* do not remove it. Tailwindcss global components layer not gonna see those classes otherwise */
:global(.tag--primary) {}
:global(.tag--secondary) {}
.dot {
  @apply w-2 h-2 mr-1 inline-block rounded;
}
</style>
